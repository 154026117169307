import { useEffect, useState } from "react";
import { DateField, FunctionField, useDataProvider } from "react-admin";


export const ProductPriceField = (props) => {
  const dataProvider = useDataProvider();
  const [currentVersion, setCurrentVersion] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const resp = await dataProvider.getManyReference('productVersion', { target: 'product.id', pagination: {page: 1, perPage: 10}, id: props.record.id, sort: { field: 'id', order: 'DESC' } });
      if (resp.data.length > 0 && props.record.id) {
        setCurrentVersion(resp.data[0]);
      }
    }
    fetchData();
  }, [dataProvider, props.record]);

  return <FunctionField label="Current price" record={currentVersion} render={(r) => `${r.price}€`}></FunctionField>
}

ProductPriceField.defaultProps = {
    addLabel: true,
};

export const ProductDateField= (props) => {
  const dataProvider = useDataProvider();
  const [currentVersion, setCurrentVersion] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const resp = await dataProvider.getManyReference('productVersion', { target: 'product.id', pagination: {page: 1, perPage: 10}, id: props.record.id, sort: { field: 'id', order: 'DESC' } });
      if (resp.data.length > 0 && props.record.id) {
        setCurrentVersion(resp.data[0]);
      }
    }
    fetchData();
  }, [dataProvider, props.record]);

  return <DateField label="Price in use since" record={currentVersion} source="date"></DateField>
}

ProductDateField.defaultProps = {
    addLabel: true,
};