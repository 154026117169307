import * as React from "react";
import { Show, SimpleShowLayout, TextField, Datagrid, BooleanField, ReferenceField, ImageField } from 'react-admin';

export const PurchaseItemShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField label="Product Version" source="productVersion.id" reference="productVersion">
        <ReferenceField label="Product" source="product.id" reference="product">
          <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <BooleanField source="active" />
            <ImageField source="image" />
          </Datagrid>
        </ReferenceField>
        <TextField source="price" />
      </ReferenceField>
      <ReferenceField label="Custom purchase" source="otherItem.id" reference="otherItem">
        <Datagrid>
          <TextField source="id" />
          <TextField source="amount" />
          <TextField source="description" />
        </Datagrid>
      </ReferenceField>
      <TextField source="count" />
    </SimpleShowLayout>
  </Show>
);