import { login } from './utils/api';
import jwt_decode from "jwt-decode";

export const authProvider = {
    // authentication
  login: async ( data ) => { 
    const response = await login({ authToken: data });
    const token = response.headers['x-paitsio-id'];
    const jwt = jwt_decode(token);
    if (jwt.user.isAdmin) {
      localStorage.setItem('token', token);
    } else {
      throw new Error('You are not Admin :(')
    }
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
        localStorage.removeItem('token');
        return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: () => {
    const token = localStorage.getItem('token');
    try {
      const jwt = jwt_decode(token);
      if (jwt.exp * 1000 > new Date()) {
        return Promise.resolve();
      }
      throw Error('token has expired');
    } catch (err) {
      console.log(err);
      return Promise.reject({ redirectTo: '/login', message: 'Token is not valid'});
    }
  },
  logout: () => {
    localStorage.removeItem('token');
    return Promise.resolve();
  },
  getIdentity: () => { 
    try {
      const jwt = jwt_decode(localStorage.getItem('token'));
      if (jwt) {
        return Promise.resolve({id: jwt.user.id, fullName: jwt.user.email, avatar: jwt.user.profilePicture})
      } else {
        return Promise.reject();
      }
    } catch (err) {
      return Promise.reject();
    }
  },
  // authorization
  getPermissions: async (params) => { return Promise.resolve()},
}