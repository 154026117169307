import { Create, NumberInput, ReferenceInput, SimpleForm, required, SelectInput } from "react-admin";

const Title = ({ record }) => <span>{`Change price for ${record.product.name}`}</span>

const redirect = (_basePath, _id, data) => `/product/${data.product.id}/show`;

export const ProductVersionCreate = (props) => (
  <Create {...props} title={<Title />}>
    <SimpleForm redirect={redirect}>
      <ReferenceInput {...props} label="Product" source="productId" reference="product" validate={[required()]} >
        <SelectInput optionText="name"></SelectInput>
      </ReferenceInput>
      <NumberInput source="price" min={0} validate={[required()]} />
    </SimpleForm>
  </Create>
);