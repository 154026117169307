import * as React from "react";
import { List, Datagrid, TextField, ImageField, Filter, EmailField, BooleanField, Edit, SimpleForm, TextInput, BooleanInput, EditButton, Toolbar, SaveButton } from 'react-admin';

const UserFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const UserList = (props) => (
    <List {...props} filters={<UserFilter/>} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <EmailField source="email" />
            <BooleanField source="isAdmin" label="Admin?" />
            <ImageField source="profilePicture" />
            <EditButton />
        </Datagrid>
    </List>
);

const EditTitle = ({ record }) => <span>{`Edit user: ${record.email}`}</span>

const PostEditActions = (props) => (
    <Toolbar {...props}>
       <SaveButton />
    </Toolbar>
);

export const UserEdit = (props) => {
  

  return <Edit {...props} title={<EditTitle/>}>
    <SimpleForm toolbar={<PostEditActions />}>
      <TextInput disabled source="id" />
      <TextInput disabled source="email" />
      <TextInput disabled source="name" />
      <BooleanInput source="isAdmin" label="Admin"></BooleanInput>
      <ImageField source="profilePicture"/>
    </SimpleForm>
  </Edit>
};