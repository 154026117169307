import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';

const PurchaseItemChipField = ({ source, record = {} }) => {
  const str = record.productVersion ? `${record.count} x ${record.productVersion.product.name}` : `Custom: ${record.otherItem.amount}`
  return <Chip label={str} />;
} 

PurchaseItemChipField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

PurchaseItemChipField.defaultProps = {
  addLabel: true,
};

export default PurchaseItemChipField;