import * as React from 'react';
import { useLogin, useNotify, Notification, defaultTheme } from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { GoogleLogin } from 'react-google-login';

export const LoginPage = ({ theme }) => {
  const login = useLogin();
  const notify = useNotify();
  const responseGoogle = (data) => {
    login(data).catch((error) => {
      notify(error.message, 'error');
    });
  };

  return (
    <ThemeProvider theme={createMuiTheme(defaultTheme)}>
      <div className="login-page">
        <div className="login-container">
          <img id="logo" src="./applogo.png" alt="Paitsio" />
          <h1 className="login-title">Piikki admin</h1>
          <GoogleLogin
            clientId="611281060111-doetdm8suor977oc1bgb664eeq6pd8co.apps.googleusercontent.com"
            buttonText="Login"
            responseType='code'
            accessType='offline'
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
          />
          <Notification />
        </div>
      </div>
    </ThemeProvider>
  );
};