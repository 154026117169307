import * as React from "react";

import Button from '@material-ui/core/Button';
import { List, Datagrid, TextField, BooleanField, EditButton, Edit, Filter, useDataProvider, SimpleForm, TextInput, required, BooleanInput, Link, Toolbar, useNotify, SaveButton, Create, NumberInput, useRedirect, useRefresh} from 'react-admin';
import { ProductDateField, ProductPriceField } from "../components/ProductVersionFields";

const ProductFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const ProductList = (props) => (
  <List filters={<ProductFilter/>} {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <BooleanField source="active" />
      <TextField source="image" label="Image URL" />
      <EditButton />
    </Datagrid>
  </List>
);

const EditTitle = ({ record }) => <span>{`Edit product: ${record.name}`}</span>

const CreateNewVersionButton = ({ record }) => (
  <Button
    style={{'margin-left': '10px'}}
    component={Link}
    variant="contained"
        to={{
            pathname: '/productVersion/create',
            search: `?source=${JSON.stringify({ productId: record.id, product: {name: record.name, id: record.id} })}`,
        }}
    >
        Change price
    </Button>
);

const CustomToolbar = props => (
    <Toolbar {...props}>
      <SaveButton />
      <CreateNewVersionButton ></CreateNewVersionButton>
    </Toolbar>
);

export const ProductEdit = (props) => {
  

  return <Edit {...props} title={<EditTitle/>}>
    <SimpleForm toolbar={<CustomToolbar/>}>
      <TextInput disabled label="Id" source="id" />
      <TextInput source="name" validate={required()} />
      <BooleanInput source="active" label="Active"></BooleanInput>
      <TextInput source="image" label="Image URL"/>
      <ProductPriceField label="Current price" />
      <ProductDateField label="Price in use since"/>
    </SimpleForm>
  </Edit>
};

export const ProductCreate = (props) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = async ({data}) => {
    await dataProvider.create('productVersion', {data:{price: data.price, product: {id: data.id}}});
    notify(`Product "${data.name}" created successfully`);
    redirect(`/product/${data.id}`);
    refresh();
  }

  return <Create onSuccess={onSuccess} {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <BooleanInput source="active" />
      <NumberInput source="price" />
      <TextInput source="image" label="Image URL"/>
    </SimpleForm>
  </Create>

}