import * as React from "react";
import { List, Datagrid, TextField, EmailField, ReferenceManyField, SingleFieldList, DateField } from 'react-admin';
import PurchaseItemChipField from '../components/PurchaseItemChip';

export const PurchaseList = (props) => (
    <List {...props}>
      <Datagrid>
        <TextField source="id" />
        <DateField source="date" />
        <EmailField label="User" source={"user.email"} />
        <ReferenceManyField label="Items purchased" reference="purchaseItem" target="purchase.id">
          <SingleFieldList>
            <PurchaseItemChipField source="count" />
          </SingleFieldList>
        </ReferenceManyField>
      </Datagrid>
    </List>
);