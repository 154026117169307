import * as React from "react";
import { fetchUtils, Admin, Resource } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';

import { UserList, UserEdit } from './views/user';
import { PurchaseList } from "./views/purchase";
import { PurchaseItemShow } from "./views/purchaseItem";
import { ProductList, ProductEdit, ProductCreate } from './views/product';
import { ProductVersionCreate } from './views/productVersion';
import { authProvider } from "./authProvider";
import { LoginPage } from "./views/login";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  if (!url.includes('login')) {
    options.headers.set('x-paitsio-id', localStorage.getItem('token'));

  }
  return fetchUtils.fetchJson(url, options);
};


const App = () => {
  const apiUrl = `${process.env.REACT_APP_API_BASE_URL || ''}${process.env.REACT_APP_ADMIN_CRUD_PREFIX || ''}`

  return <Admin dataProvider={simpleRestProvider(apiUrl, httpClient)} authProvider={authProvider} loginPage={LoginPage}>
    <Resource name="user" list={UserList} edit={UserEdit} />
    <Resource name="purchase" list={PurchaseList} />
    <Resource name="purchaseItem" show={PurchaseItemShow} />
    <Resource name="product" list={ProductList} edit={ProductEdit} create={ProductCreate} />
    <Resource name="productVersion" create={ProductVersionCreate}></Resource>
    <Resource name="otherItem" />
  </Admin>
};

export default App;